/* eslint-disable import/no-anonymous-default-export */
export default {
  GUEST_LOGIN: '/api/v1/guest/login',
  ADD_PLAYER_ID: '/api/v1/notification/player/add',
  DELETE_PLAYER_ID: '/api/v1/notification/player/delete',
  GUEST_REQUEST: '/api/v1/notification/guest-request',
  GET_HOTEL_DOORS: '/api/v1/hotel/getHotelDoors',
  OPEN_GUEST_DOOR: '/api/v1/hotel/openGuestDoor',
  FEEDBACK_NOTIFICATION: '/api/v1/notification/feedbackNotification',
  PMS_RESERVATIONS: '/api/v1/pms/getPmsReservations',
  PMS_GUESTPRECHECKIN_REQUEST :'api/v1/pms/guestPreCheckinRequest',
  FETCH_PARTNER_CATEGORY: '/api/v1/partners/fetch-Partner-Categories',
  FETCH_PARTNER_LIST_BY_CATEGORY: '/api/v1/partners/fetch-Partners-list-by-category'
}
